export const routes = {
    home: '/',
    contact: '/contact',
    reservation: '/reservation',
    thankYou: '/thank-you',
    jobApplication: '/job_application',
    company: '/company',
    about: '/about',
    menu: '/menu',
    mainMenu: "/mainMenu",
    privacyPolicy: "/privacy-policy",
    news: "/news",
    news_articles: "/news/articles",
    news_jobs: "/news/jobs",
    about_message: "/about/message",
    about_feature: "/about/feature",
    about_important: "/about/important",
    about_academy: "/about/academy",
    about_product: "/about/product",
    about_access: "/about/access",
    product: "/product",
    authentication_email: "/authentication_email",
    resetPassword: "/reset_password",
    storeProduct: "/store-product",
    productDetail: "/product-detail",
    test: "/test"
}